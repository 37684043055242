import React from "react"
import SeoHead from "../components/global/seoHead"
import Layout from "../components/global/layout"
import Seo from "../components/global/seo"
import ScrollIndication from "../components/global/scrollIndication"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import "../sass/blog/blog-single.scss"
import "../sass/blog/wordpress-editor.scss"

const readingTime = require('reading-time');
const options = { wordsPerMinute: 400 }

export const Head = ({ data: { post } }) => (
  <>
    <SeoHead
     ogImage={post.featuredImage.node.localFile.childImageSharp.original.src}
      title={post.seo.title}
      description={post.seo.metaDesc}
    />    
  </> 
)

const BlogPostTemplate = ({ data: { post } }) => {

  return (
    <Layout>
      <Seo bclass="blog-single" bid="blog-single"/>
      <ScrollIndication />
      <section className="blog-content">
        <Container fluid="xl">
          <Row className="justify-content-center">
              <Col lg={8} md={10}>
                <article
                  className="blog-post"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <p class="text-center ct-header">
                  <Link to="/blog/" className="all-blog">All Blog</Link>&nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;<Link to={post.categories.nodes[0]['uri']}>{post.categories.nodes[0]['name']}</Link>
                   </p>
                    <h1 itemProp="headline" class="text-center">{post.title}</h1>
                    <p className="text-center ct-footer"><span>By {post.author.node.name}</span>&nbsp;&nbsp;.&nbsp;&nbsp;<span>{post.date}</span><span className="d-md-inline-block d-none">&nbsp;&nbsp;.&nbsp;&nbsp;</span> <span className="d-md-inline-block d-block mt-md-0 mt-2">{readingTime(post.content, options).text}</span></p>
                    <GatsbyImage image={getImage(post.featuredImage.node.localFile)} alt={ post.featuredImage.node.altText } className="img-auther" itemProp="image"/>
                  <div class="content-wrapp mt-4">
                    {!!post.content && (
                      <div itemProp="articleBody" dangerouslySetInnerHTML={{ __html: post.content }} />
                    )}
                  </div>
              </article>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8} md={10}>
              <div className="author-details" itemscope="" itemprop="author" itemtype="http://schema.org/Person">
                <Row className="text-md-left text-center">
                  <Col md={2} className="px-0 mb-4 mb-md-0 d-flex justify-content-center">
                    <div className="avatar text-center">
                    <GatsbyImage image={getImage(post.author.node.usersAcf.uploadProfileImage.localFile)} alt="" itemprop="image"/>
                    </div>
                  </Col>
                  <Col md={10}>
                    <div className="details">
                      <p>WRITTEN BY</p>
                      <h4 itemprop="name">{post.author.node.name}</h4>
                      <article>
                        <p dangerouslySetInnerHTML={{ __html: post.author.node.description }} />
                      </article>
                    </div>
                  </Col>                  
                </Row>
              </div>
            </Col>
          </Row>
          
        </Container>
      </section>
      <section className="related-blogs">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="sub-title text-center">You might also like</h2>
            </Col>
          </Row>
        </Container>
        <Container class="listing-rblogs">
          <Row>
          {post.relPost.relatedBlog.map( (rblog, index) => (
            <Col md={6} lg={4}>              
            <article className="blog-article">
              <div className="blog-link">
                <div className="artile-image">
                  <Link to={rblog.uri} className="thumb-url" id={'thumnb-url-'+index} data-url={'#title-url-'+index}>
                    <GatsbyImage image={getImage(rblog.featuredImage.node.localFile)} alt={ rblog.featuredImage.node.altText } />
                  </Link>
                </div>
                <div className="blog-meta">
                  <time dateTime={rblog.date}>{rblog.date}</time>
                  <span>
                    <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5.7998" cy="5" r="4" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></circle>
                        <path d="M9.65337 2.24106C9.23422 2.10134 8.78117 2.32787 8.64145 2.74702C8.50173 3.16617 8.72826 3.61922 9.14741 3.75894L9.65337 2.24106ZM14.4534 3.75894C14.8725 3.61922 15.099 3.16617 14.9593 2.74702C14.8196 2.32787 14.3666 2.10134 13.9474 2.24106L14.4534 3.75894ZM11.8004 3.8L11.5474 4.55894C11.7116 4.61368 11.8892 4.61368 12.0534 4.55894L11.8004 3.8ZM9.14741 3.75894L11.5474 4.55894L12.0534 3.04106L9.65337 2.24106L9.14741 3.75894ZM12.0534 4.55894L14.4534 3.75894L13.9474 2.24106L11.5474 3.04106L12.0534 4.55894Z" fill="#B8B8B8"></path> <path d="M22.5996 5.79922L22.5996 4.19922" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></path>
                        <path d="M1 5.79922L1 4.19922" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></path>
                        <circle cx="17.7998" cy="5" r="4" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></circle> </svg>
                    <span className="span-reading-time">
                      {
                      readingTime(rblog.content, options).text
                      }
                    </span>
                </span>
                <Link to={rblog.categories.nodes[0]['uri']}>{rblog.categories.nodes[0]['name']}</Link>                    
                </div>
                <div class="blog-meta-content">
                  <Link to={rblog.uri} className="title-url" id={'title-url-'+index} data-url={'#thumnb-url-'+index}>
                    <h2>{rblog.title}</h2>
                  </Link>
                </div>
                <div className="user-details">
                  <div className="avatar">
                  <GatsbyImage image={getImage(rblog.author.node.usersAcf.uploadProfileImage.localFile)} alt="" />
                  </div>
                  <div class="details">
                    <strong>{rblog.author.node.name}</strong>
                    <span>{rblog.author.node.usersAcf.designation}</span>
                  </div>                    
                </div>                  
              </div>
            </article>
          </Col>        
      ))}
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate


export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "MMMM DD, YYYY")
      seo {
        title
        metaDesc
      }
      relPost {
        externalLink
      }      
      categories {
        nodes {
          name
          uri
        }
      }
      author {
        node {
          name
          description
          usersAcf {
            designation
            uploadProfileImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: AUTO, width: 100, height: 100, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              original {
                src
              }
            }
          }
        }
      }
      
      tags {
        nodes {
          name
          uri
        }
      }
      relPost {
        relatedBlog {
          ... on WpPost {
            title
            uri
            slug
            content
            date(formatString: "MMM DD, YYYY")
            categories {
              nodes {
                name
                uri
              }
            }
            author {
              node {
                name
                usersAcf {
                  designation
                  uploadProfileImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(formats: AUTO, width: 35, height: 35, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
            }                
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(formats: AUTO, width: 386, height: 216, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }            
    }
  }
`